import React, { useState, useEffect, useRef, forwardRef, useMemo } from "react";
import { Chess } from "chess.js";
import { Chessboard } from "react-chessboard";
import {
    AppBar, Toolbar, Typography, Container, Grid, Paper, Button,
    ToggleButton, ToggleButtonGroup, Table, TableBody, TableCell, TableHead, TableRow,
    Box, Dialog, DialogTitle, DialogContent, DialogActions, CssBaseline,
    CircularProgress, Slide, Zoom, Grow,
    Snackbar, Alert // Keep Snackbar and Alert
} from "@mui/material";
import { ThemeProvider, keyframes } from "@mui/material/styles"; // Import keyframes
import theme from "./theme"; // Import the theme
import { initializeStockfish, evaluatePositionSequentially } from "./chessUtils"; // Assuming chessUtils exists
// import CustomSquareRenderer from "./components/CustomSquareRenderer"; // Uncomment if using CustomSquareRenderer
import {
    computeExpectedPoints, classifyMoveComposite, getBotMoveFromLichess,
    getFreshStockfishMove, getArrowColor, getArrowColorFromClassification, getEmojiForRating, getSlangForRating // Import new helper
} from "./helper";

const FIXED_BOARD_WIDTH = 480; // Keep fixed for layout stability

// --- Define Keyframe Animations ---
const pulseGlow = keyframes`
  0% { box-shadow: 0 0 5px 2px ${theme.palette.fireMeter.feverGradient.split(',')[1].trim()}; opacity: 1; }
  50% { box-shadow: 0 0 15px 5px ${theme.palette.fireMeter.feverGradient.split(',')[0].trim()}; opacity: 0.8; }
  100% { box-shadow: 0 0 5px 2px ${theme.palette.fireMeter.feverGradient.split(',')[1].trim()}; opacity: 1; }
`;

const shake = keyframes`
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
`;

const scoreUp = keyframes`
  from { transform: translateY(0) scale(1); opacity: 1; color: ${theme.palette.moveQuality.Excellent}; } /* Use a positive color */
  to { transform: translateY(-15px) scale(1.1); opacity: 0; color: ${theme.palette.moveQuality.Brilliant}; }
`;

const scoreDown = keyframes`
  from { transform: translateY(0) scale(1); opacity: 1; color: ${theme.palette.moveQuality.Mistake}; } /* Use a negative color */
  to { transform: translateY(15px) scale(0.9); opacity: 0; color: ${theme.palette.moveQuality.Blunder}; }
`;


function App() {
    // --- Basic game state ---
    const [chess, setChess] = useState(new Chess());
    const [fen, setFen] = useState(chess.fen());
    const [moveHistory, setMoveHistory] = useState([]); // Start empty
    const [engineEval, setEngineEval] = useState(null); // Stockfish eval (white's perspective)
    const [pvMoves, setPvMoves] = useState([]); // Principal variation moves (as strings)
    const [customArrows, setCustomArrows] = useState({}); // Persistent arrows across runs { [fen]: [{ from, to, color, uci }, ...] }
    const [memoryMoves, setMemoryMoves] = useState({}); // Arrows for the current run only { [fen]: [{ from, to, color, uci }, ...] }
    const [runsHistory, setRunsHistory] = useState([]); // History of past runs
    // const [moveBadges, setMoveBadges] = useState({}); // Use if CustomSquareRenderer needs it
    const [isCalculating, setIsCalculating] = useState(false); // Player move evaluation/processing
    const [isEvaluating, setIsEvaluating] = useState(false); // Stockfish passively evaluating position
    const [isBotThinking, setIsBotThinking] = useState(false); // Bot actively choosing move
    const [selectedRatings, setSelectedRatings] = useState(["2200", "2500"]); // Default ratings

    // --- Gamification State ---
    const [fireMeter, setFireMeter] = useState(0);
    const [isFeverMode, setIsFeverMode] = useState(false);
    const [runScore, setRunScore] = useState(0);
    const [lastMoveRating, setLastMoveRating] = useState(null); // Track last move quality for feedback styling
    const [feedbackText, setFeedbackText] = useState(""); // For text popups ("Galaxy Brain!")
    const [feedbackEmoji, setFeedbackEmoji] = useState(""); // For emoji popups (👑)
    const [showFeedback, setShowFeedback] = useState(false); // Controls feedback popup visibility
    const [scoreChange, setScoreChange] = useState(0); // Stores score change amount for animation

    // --- Dialog state ---
    const [continueStockfish, setContinueStockfish] = useState(false); // Use Stockfish if Lichess fails?
    const [openContinueDialog, setOpenContinueDialog] = useState(false);
    const [pendingGame, setPendingGame] = useState(null); // Game state when dialog opens

    // --- Toast State --- (NEW)
    const [showInvalidMoveToast, setShowInvalidMoveToast] = useState(false);
    const [invalidMoveDetails, setInvalidMoveDetails] = useState("");

    // --- Lichess Explorer Data State ---
    const [explorerData, setExplorerData] = useState(null);
    const [explorerLoading, setExplorerLoading] = useState(false);
    const [explorerError, setExplorerError] = useState(null);

    const stockfishRef = useRef(null); // Reference to Stockfish worker
    const boardWidth = FIXED_BOARD_WIDTH;
    const feedbackTimeoutRef = useRef(null); // Ref for feedback visibility timeout

    // --- Lichess Explorer Data Fetching ---
    useEffect(() => {
        const fetchExplorerData = async () => {
            // Don't fetch for the starting position immediately or if fen is invalid
            if (!fen || fen === new Chess().fen()) {
                setExplorerData(null);
                setExplorerLoading(false);
                setExplorerError(null);
                return;
            }
            setExplorerLoading(true); setExplorerError(null); setExplorerData(null);
            try {
                const baseUrl = "https://explorer.lichess.ovh/lichess";
                const ratingParam = selectedRatings.length > 0 ? selectedRatings.join(",") : "1600,1800,2000"; // Fallback ratings
                const params = new URLSearchParams({ variant: "standard", speeds: "blitz,rapid,classical", ratings: ratingParam, fen });
                const url = `${baseUrl}?${params.toString()}`;
                const resp = await fetch(url);
                if (!resp.ok) { const errorData = await resp.text(); throw new Error(`Lichess API error: ${resp.status} ${resp.statusText} - ${errorData}`); }
                const data = await resp.json();
                setExplorerData(data);
            } catch (err) {
                console.error("Lichess Explorer fetch failed:", err);
                setExplorerError(err.message || "Failed to fetch explorer data"); setExplorerData(null);
            } finally {
                setExplorerLoading(false);
            }
        };
        // Debounce the fetch
        const debounceTimeout = setTimeout(() => { fetchExplorerData(); }, 350); // Slightly longer debounce
        return () => clearTimeout(debounceTimeout); // Cleanup timeout on unmount or dep change
    }, [fen, selectedRatings]); // Re-fetch when FEN or selected ratings change

    // Helper to wrap PV text
    const wrapText = (text, limit = 60) => {
        return text ? text.match(new RegExp(`.{1,${limit}}`, "g"))?.join("\n") || text : "";
    };

    // Compute combined table moves from explorerData
    const combinedTableMoves = useMemo(() => {
        if (explorerData?.moves && Array.isArray(explorerData.moves)) {
            return explorerData.moves.map((m) => {
                const totalGames = (m.white || 0) + (m.draws || 0) + (m.black || 0);
                // Check whose turn it is *now* based on the FEN the explorer data was fetched for
                // We can rely on the current `chess` object's turn() since explorerData depends on `fen` which depends on `chess`
                const turnIsWhite = chess.turn() === "w";
                // Calculate win/loss from the perspective of the player whose turn it is
                const winPct = totalGames > 0 ? ((turnIsWhite ? m.white : m.black) / totalGames) * 100 : null;
                const lossPct = totalGames > 0 ? ((turnIsWhite ? m.black : m.white) / totalGames) * 100 : null;
                const drawPct = totalGames > 0 ? (m.draws / totalGames) * 100 : null;
                return { san: m.san, uci: m.uci, totalGames, winPct, lossPct, drawPct };
            });
        } return [];
    }, [explorerData, chess]); // Depends on explorerData and current turn

    // Handle Rating Toggle
    const handleRatingToggle = (event, newRatings) => {
        // Ensure at least one rating is selected, or provide a default
       if (newRatings.length === 0) {
            // Optionally prevent deselection or set a default
            // For now, allow empty selection which defaults in API call
            // Update: Let's default to a common range if empty to avoid errors / max strength stockfish
             setSelectedRatings(["1600", "1800", "2000"]);
             return; // Exit early after setting default
       }
       setSelectedRatings(newRatings);
    };

    // NEW RUN: Reset game state, merge arrows, save history
    const newRun = () => {
        // 1. Merge current run's arrows (memoryMoves) into persistent storage (customArrows)
        setCustomArrows(prevCustom => {
            const newCustom = { ...prevCustom };
            for (const fenKey in memoryMoves) {
                // Avoid duplicates if the same move was somehow added twice
                const currentFenMemoryMoves = memoryMoves[fenKey] || [];
                const existingFenCustomMoves = newCustom[fenKey] || [];

                // Simple de-duplication based on UCI string within the same FEN
                const combined = [...existingFenCustomMoves, ...currentFenMemoryMoves];
                const uniqueMovesMap = new Map();
                combined.forEach(move => {
                    const key = `${move.from}-${move.to}`; // Use from-to as unique key for an arrow in a given FEN
                    // Keep the latest version of the arrow (from memoryMoves if present)
                    uniqueMovesMap.set(key, move);
                });
                newCustom[fenKey] = Array.from(uniqueMovesMap.values());
            }
            return newCustom;
        });

        // 2. Save current run to history if moves were made
        if (moveHistory.length > 0) {
            const runData = {
                runNumber: runsHistory.length + 1,
                moveHistory: [...moveHistory], // Use the state variable
                finalFen: fen,
                finalEval: engineEval,
                finalScore: runScore,
                timestamp: new Date().toISOString(),
            };
            setRunsHistory(prevRuns => [runData, ...prevRuns]); // Add to the beginning
        }

        // 3. Reset game state
        const newGame = new Chess(); // Create new Chess instance
        setChess(newGame);
        setFen(newGame.fen());
        setMoveHistory([]); // Reset move history
        setMemoryMoves({}); // Clear current run's arrows
        setContinueStockfish(false); // Reset Lichess/Stockfish fallback flag
        setFireMeter(0);
        setIsFeverMode(false);
        setRunScore(0);
        setEngineEval(null); // Reset eval
        setPvMoves([]); // Reset principal variation
        setLastMoveRating(null); // Reset feedback state
        setFeedbackText("");
        setFeedbackEmoji("");
        setShowFeedback(false);
        setScoreChange(0);
        setExplorerData(null); // Clear explorer data
        setExplorerError(null);
        if (feedbackTimeoutRef.current) clearTimeout(feedbackTimeoutRef.current); // Clear pending feedback hide
        setShowInvalidMoveToast(false); // Reset invalid move toast state

        // 4. Start initial evaluation for the new game
        if (stockfishRef.current) {
            console.log("SF: New Run - Initializing evaluation");
            stockfishRef.current.postMessage("stop"); // Stop anything previous first
            stockfishRef.current.postMessage("ucinewgame"); // Tell engine it's a new game
            stockfishRef.current.postMessage(`position fen ${newGame.fen()}`);
            stockfishRef.current.postMessage("go depth 16"); // Start analysis
            setIsEvaluating(true);
        } else {
            console.warn("Stockfish ref not available on new run.");
        }
    };

    // Initialize Stockfish on component mount
    useEffect(() => {
        initializeStockfish(
            stockfishRef,
            (evalScore) => { // onEval callback
                setEngineEval(evalScore);
                setIsEvaluating(false); // Passive evaluation finished/updated (might restart immediately if FEN changes)
            },
            (pv) => { // onPv callback
                setPvMoves(pv);
            },
            chess // Pass initial chess instance if needed by init function
        );

        // Configure Stockfish settings after initialization
        const configureStockfish = () => {
            if (stockfishRef.current) {
                console.log("Configuring Stockfish...");
                stockfishRef.current.postMessage(`setoption name MultiPV value 3`); // Show top 3 lines
                // Set Skill Level based on selected ratings (run once now, and also in effect below)
                updateStockfishSkillLevel();
                // Start analyzing the initial position
                console.log("SF: Initial Mount - Starting evaluation");
                stockfishRef.current.postMessage("stop"); // Stop just in case
                stockfishRef.current.postMessage(`position fen ${fen}`); // Use initial fen state
                stockfishRef.current.postMessage("go depth 16");
                setIsEvaluating(true);
            } else {
                // Retry initialization if failed?
                console.error("Stockfish initialization failed or ref not set.");
            }
        };

        // Wait a brief moment for the worker to potentially initialize
        const initTimeout = setTimeout(configureStockfish, 500);

        // Cleanup function on component unmount
        return () => {
            clearTimeout(initTimeout);
            if (stockfishRef.current) {
                console.log("Terminating Stockfish worker.");
                try {
                    stockfishRef.current.postMessage("quit"); // Gracefully shut down
                } catch (e) {
                    console.warn("Error sending 'quit' to Stockfish worker (may have already terminated).");
                }
                stockfishRef.current.terminate(); // Force terminate if needed
                stockfishRef.current = null;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Run only on mount

    // Update Stockfish position when FEN changes externally or after bot move
      useEffect(() => {
          // Only update if Stockfish is ready and not currently busy with player move calc or bot thinking
          // Let initial evaluation be handled by the mount effect
          if (stockfishRef.current && !isCalculating && !isBotThinking) {
              // console.log("SF Effect [fen]: Updating position", fen);
              stockfishRef.current.postMessage("stop"); // Stop previous calculation
              stockfishRef.current.postMessage(`position fen ${fen}`);
              stockfishRef.current.postMessage("go depth 16"); // Start analyzing new position
              setIsEvaluating(true); // Show passive evaluation state
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [fen]); // Dependency: fen (removed isCalculating, isBotThinking to simplify - stop message handles interruptions)

      // Function to update Stockfish skill level based on selected ratings
      const updateStockfishSkillLevel = () => {
          if (stockfishRef.current) {
              if (selectedRatings.length > 0) {
                  const numericRatings = selectedRatings.map(r => parseInt(r, 10)).filter(r => !isNaN(r));
                  // Use the HIGHEST selected rating to set skill, or average? Let's try average.
                  const avgRating = numericRatings.length > 0
                      ? numericRatings.reduce((acc, r) => acc + r, 0) / numericRatings.length
                      : 1600; // Default if somehow empty despite handler logic

                  // Map average rating (e.g., 400-2800+) to UCI Skill Level (0-20)
                  // Adjusted mapping: Aim for level 0 around 800 Elo, level 20 around 2800+ Elo
                  // Scale: 20 levels over 2000 Elo points => 1 level per 100 Elo
                  let skillLevel = Math.round((avgRating - 800) / 100);
                  skillLevel = Math.max(0, Math.min(20, skillLevel)); // Clamp between 0 and 20
                  console.log(`Setting Stockfish Skill Level to ${skillLevel} (based on avg rating ${avgRating.toFixed(0)})`);
                  stockfishRef.current.postMessage(`setoption name UCI_LimitStrength value true`); // Enable skill limit
                  stockfishRef.current.postMessage(`setoption name Skill Level value ${skillLevel}`);
              } else {
                  // Should not happen due to handleRatingToggle logic, but as a fallback:
                   console.log("Disabling Stockfish Skill Level limit (no ratings selected).");
                  stockfishRef.current.postMessage(`setoption name UCI_LimitStrength value false`);
                  // Optionally set skill level to max (20) when limit is off
                  // stockfishRef.current.postMessage(`setoption name Skill Level value 20`); // Keep Skill Level high if strength limit is off
              }
          }
     };

    // Update Stockfish Strength when selectedRatings change
    useEffect(() => {
        // console.log("SF Effect [selectedRatings]: Updating skill level"); // Debug
        updateStockfishSkillLevel();
        // Debounce or delay needed? Probably not, applying the setting is quick.
    }, [selectedRatings]);


    // --- ENHANCED Gamification Updates ---

    // Show move feedback text/emoji
    const triggerFeedback = (rating, points) => {
        setLastMoveRating(rating); // Store rating for styling feedback box
        setFeedbackEmoji(getEmojiForRating(rating));
        setFeedbackText(getSlangForRating(rating));
        setScoreChange(points); // Set score change amount for animation display
        setShowFeedback(true); // Make feedback visible

        // Clear previous timeout if one exists (e.g., user makes moves very quickly)
        if (feedbackTimeoutRef.current) {
            clearTimeout(feedbackTimeoutRef.current);
        }

        // Set new timeout to hide feedback after a duration
        feedbackTimeoutRef.current = setTimeout(() => {
            setShowFeedback(false);
            // Consider resetting scoreChange here AFTER animation completes?
            // The animation itself handles fade-out, maybe keep scoreChange until next move?
            // Resetting here for simplicity:
            // setScoreChange(0); // Reset score change display
        }, 2100); // Duration feedback is shown (slightly longer than animation)
    };

    // Update Fire Meter (Using updated classifications)
    const updateFireMeter = (ratingClassification) => {
        setFireMeter((prev) => {
            let delta = 0;
            switch (ratingClassification) {
                // Positive Moves
                case "Best": delta = 2; break;      // Small boost for holding
                case "Good": delta = 6; break;      // Decent boost (was 5)
                case "Excellent": delta = 10; break; // Good boost (was 8)
                case "Great": delta = 15; break;     // Strong boost (was 12)
                case "Brilliant": delta = 25; break; // Large boost (was 20)
                // Negative Moves
                case "Inaccuracy": delta = -8; break; // Moderate decrease
                case "Mistake": delta = -18; break;   // Large decrease (was -15)
                case "Blunder": delta = -35; break;   // Massive decrease (was -30)
                default: delta = 0;
            }
            // Add a bonus/multiplier in Fever Mode for positive moves
            if (isFeverMode && delta > 0) {
                delta = Math.round(delta * 1.30); // 30% boost in fever mode (was 1.25)
            }
             // Dampen negative moves slightly in fever mode? Or keep punishing? Keep punishing for now.

            const newVal = Math.min(100, Math.max(0, prev + delta)); // Clamp between 0 and 100
            // Manage Fever Mode state transitions
            if (newVal === 100 && !isFeverMode) {
                setIsFeverMode(true);
                // Optional: Trigger a "Fever Mode Activated" notification/sound
                console.log("FEVER MODE ACTIVATED!");
            } else if (newVal < 100 && isFeverMode) {
                // Check if the delta was negative *before* clamping made it < 100
                // Only deactivate if the move itself caused the drop below 100
                if (prev + delta < 100) {
                    setIsFeverMode(false);
                    // Optional: Trigger "Fever Mode Ended" notification/sound
                    console.log("Fever Mode Ended.");
                }
            } else if (newVal < 95 && isFeverMode) { // More robust check? If it drops significantly below 100
                    setIsFeverMode(false);
                    console.log("Fever Mode Ended.");
            }
            return newVal;
        });
    };

    // Update Run Score (Using updated classifications & Fever Mode bonus)
    const updateRunScore = (ratingClassification) => {
        let points = 0;
        switch (ratingClassification) {
            // Penalties harsher
            case "Blunder": points = -60; break; // was -50
            case "Mistake": points = -30; break; // was -25
            case "Inaccuracy": points = -12; break; // was -10
            // Rewards scaled up, especially for top moves
            case "Best": points = 5; break;      // Keep base
            case "Good": points = 15; break;     // Keep base
            case "Excellent": points = 30; break; // was 25
            case "Great": points = 50; break;     // was 40
            case "Brilliant": points = 100; break;// was 75 - Make it really count!
            default: points = 0;
        }
        if (isFeverMode) {
            if (points > 0) {
                points = Math.round(points * 1.75); // Big Fever bonus! (75%)
            } else if (points < 0) {
                // Slightly lessen penalty in fever mode? Or keep it harsh?
                // points = Math.round(points * 0.8); // Example: 20% reduction
                // Keep harsh for now.
            }
        }
        setRunScore((prev) => prev + points); // Update the score state
        return points; // Return points change for feedback trigger
    };


// onDrop Handler: Process player's move
  const onDrop = (sourceSquare, targetSquare, piece) => {
    // Prevent moves during calculation or bot thinking or game over
    if (isCalculating || isBotThinking || chess.isGameOver()) return false;

    // Create a temporary game instance to validate the move
    const tempGame = new Chess(fen); // Use current FEN
    let legalMove = null;
    try {
        legalMove = tempGame.move({
            from: sourceSquare,
            to: targetSquare,
            promotion: "q", // Assume queen promotion for simplicity if needed
        });
    } catch (error) {
        // This catch block might be redundant if chess.js move returns null for illegal moves,
        // but it's good practice for unexpected errors.
        console.warn("Error validating move with chess.js (might be illegal):", sourceSquare, targetSquare, error);
        return false; // Indicate the move was not made
    }

    // If the move is illegal, chess.js returns null
    if (legalMove === null) {
        console.log("Illegal move attempted:", sourceSquare, targetSquare);
        return false; // Indicate the move was not made
    }

    // --- Move is Legal - Start Processing ---
    setIsCalculating(true);
    setIsEvaluating(true); // Indicate evaluation is happening (even if passive catches up later)

    const preMoveEval = engineEval !== null ? engineEval : 0; // Use last known eval (White's perspective)
    const currentFen = fen; // FEN before the player's move
    const playerTurn = chess.turn(); // Turn *before* this move ('w' or 'b')

    // Create the new game state *after* the validated move
    const newGame = new Chess(fen); // Start from the FEN before the move
    const appliedMove = newGame.move(legalMove); // Apply the validated move object
    const newFen = newGame.fen(); // FEN *after* the player's move
    const newHistory = [...moveHistory, appliedMove]; // Add the full move object

    // --- Arrow Logic: Add temporary arrow for the player's move ---
    setMemoryMoves(prev => {
        const existing = prev[currentFen] || [];
        const tempColor = theme.palette.text.secondary + 'AA'; // Greyish temporary color
        // Ensure UCI is consistent (chess.js provides it in the move object)
        const uci = appliedMove.uci || (appliedMove.from + appliedMove.to + (appliedMove.promotion || ''));
        return {
            ...prev,
            // Store arrow against the FEN *before* the move was made
            [currentFen]: [
                ...existing,
                // Add the new temporary arrow
                { from: sourceSquare, to: targetSquare, color: tempColor, uci: uci },
            ],
        };
    });

    // --- Update Core UI State ---
    setChess(newGame); // Update the main chess instance
    setFen(newFen);     // Update the FEN state (triggers passive eval useEffect)
    setMoveHistory(newHistory); // Update history

    // Reset feedback from previous move immediately
    setLastMoveRating(null);
    setShowFeedback(false);
    if (feedbackTimeoutRef.current) clearTimeout(feedbackTimeoutRef.current);

    // Stop any ongoing Stockfish calculation explicitly before starting evaluation
    if (stockfishRef.current) {
        stockfishRef.current.postMessage("stop");
    }

    // --- Asynchronously Evaluate the position *after* the player's move ---
    // Use the dedicated function which handles Stockfish communication
    evaluatePositionSequentially(newFen, stockfishRef)
        .then((newEvalResult) => { // newEvalResult = eval (pawns) from White's perspective AFTER the move

            // --- Move Classification and Scoring Logic ---
            // Calculate values needed for classification (potentially using flipped eval)
            let newEvalClassification = newEvalResult;
            if (newGame.turn() === "b") { // Check turn AFTER the move (i.e., whose turn it is now)
                 newEvalClassification = -newEvalClassification; // Flip for black's perspective if it's black's turn
            }

            // Calculate rating parameters
            const numericRatings = selectedRatings.map(r => parseInt(r, 10)).filter(r => !isNaN(r));
            const avgRating = numericRatings.length > 0 ? numericRatings.reduce((acc, r) => acc + r, 0) / numericRatings.length : 1600;

            // Calculate expected points before and after (using potentially flipped eval for 'after')
            const preExp = computeExpectedPoints(preMoveEval, avgRating); // Uses White's perspective pre-eval
            const postExpClassification = computeExpectedPoints(newEvalClassification, avgRating);

            // Calculate raw delta using potentially flipped post-eval
            const rawDeltaClassification = newEvalClassification - preMoveEval; // Might be comparing WhitePre vs BlackPost if flipped

            // Classify the move based on these potentially perspective-mixed values
            const ratingClassification = classifyMoveComposite(preExp, postExpClassification, rawDeltaClassification);

            // Update score and fire meter based on the classification
            const pointsEarned = updateRunScore(ratingClassification);
            updateFireMeter(ratingClassification);
            triggerFeedback(ratingClassification, pointsEarned); // Show feedback popup

            // --- Arrow Color Update ---
            // Determine arrow color based *only* on the final classification
            const finalArrowColor = getArrowColorFromClassification(ratingClassification);

            // Update the color of the temporary arrow added earlier
            setMemoryMoves(prev => {
                const updated = { ...prev };
                // Get moves for the FEN *before* the player's move
                const movesForFen = updated[currentFen] || [];
                const uciToUpdate = appliedMove.uci || (appliedMove.from + appliedMove.to + (appliedMove.promotion || ''));
                // Find the arrow by UCI and update its color
                updated[currentFen] = movesForFen.map((m) =>
                    m.uci === uciToUpdate
                        ? { ...m, color: finalArrowColor }
                        : m
                );
                return updated;
            });

            // --- Post-Evaluation Tasks ---
            setIsCalculating(false); // Player move processing finished

            // Set the main engine eval state to the *unflipped* result (White's perspective)
            // This ensures the displayed eval is consistent regardless of whose turn it is.
            setEngineEval(newEvalResult);

            // Start passive evaluation for the new position if game is not over
            // (This might be redundant if the FEN useEffect already handles it, but ensures it happens)
            if (!newGame.isGameOver() && stockfishRef.current) {
                // console.log("SF: Starting passive eval after player move evaluation completed.");
                stockfishRef.current.postMessage("stop"); // Ensure previous is stopped
                stockfishRef.current.postMessage(`position fen ${newFen}`);
                stockfishRef.current.postMessage("go depth 16"); // Use a reasonable depth
                setIsEvaluating(true); // Show passive evaluation state
            } else {
                setIsEvaluating(false); // Ensure evaluating state is off if game over
            }

            // --- Trigger Bot Move if Applicable ---
            if (!newGame.isGameOver() && newGame.turn() === "b") { // Check if it's bot's turn
                setIsBotThinking(true); // Show bot thinking indicator
                // Add a slight delay before the bot moves for better UX
                setTimeout(() => doBotMove(newGame), 750); // Pass the updated game state
            } else {
                // Handle Game Over after player's move
                if (newGame.isGameOver()) {
                    console.log("Game Over (after player move):", newGame.isCheckmate() ? "Checkmate" : newGame.isStalemate() ? "Stalemate" : newGame.isDraw() ? "Draw" : "Other reason");
                    setFeedbackText(newGame.isCheckmate() ? "CHECKMATE!" : "GAME OVER");
                    setFeedbackEmoji(newGame.isCheckmate() ? "🏆" : "🏁"); // Player wins = trophy
                    setShowFeedback(true);
                    if (feedbackTimeoutRef.current) clearTimeout(feedbackTimeoutRef.current); // Don't auto-hide game over message
                }
            }
        })
        .catch((error) => {
            // Handle errors during the evaluation process
            console.error("Error during sequential move evaluation:", error);
            setIsCalculating(false); // Reset flags
            setIsEvaluating(false);
            setIsBotThinking(false); // Ensure bot doesn't try to move
            setEngineEval(null); // Clear potentially inconsistent eval
            // Show an error message to the user
            setFeedbackText("Evaluation Error!");
            setFeedbackEmoji("⚠️");
            setShowFeedback(true);
            // Clear any existing feedback timeout and set a new one for the error
            if (feedbackTimeoutRef.current) clearTimeout(feedbackTimeoutRef.current);
            feedbackTimeoutRef.current = setTimeout(() => setShowFeedback(false), 3000);
        });

    // Return true to indicate the move was accepted by the board component
    return true;
};


    // Bot Move Handler (With Castling UCI Correction)
    const doBotMove = async (gameInstance) => {
        const currentBotFen = gameInstance.fen();

        // Exit if game ended before bot could move or it's not bot's turn
        if (gameInstance.isGameOver() || gameInstance.turn() !== "b") { // Assuming bot is 'b'
            console.log("Bot move skipped: Game over or not bot's turn.");
            setIsBotThinking(false);
            setIsCalculating(false); // Ensure this is off too
            // Ensure passive eval is running if game isn't over
             if (!gameInstance.isGameOver() && stockfishRef.current) {
                  stockfishRef.current.postMessage("stop");
                  stockfishRef.current.postMessage(`position fen ${currentBotFen}`);
                  stockfishRef.current.postMessage("go depth 16");
                  setIsEvaluating(true);
             }
            return;
        }

        // Stop any ongoing passive analysis
        if (stockfishRef.current) {
            // console.log("SF: Stopping passive eval for bot move decision");
            stockfishRef.current.postMessage("stop");
            setIsEvaluating(false); // Stop showing passive eval during bot thinking
        }

        let bestUci = null;
        let moveSource = "Lichess"; // Track where the move came from

        // --- Try Lichess Explorer ---
        if (!continueStockfish) {
            try {
                bestUci = await getBotMoveFromLichess(currentBotFen, selectedRatings);
                // console.log("Lichess API raw returned UCI:", bestUci); // Debug raw value if needed

                // --- *** START FIX for non-standard Castling UCI *** ---
                // This corrects the "KingStart-RookStart" pattern (e.g., e8h8)
                // to the standard "KingStart-KingEnd" UCI (e.g., e8g8)
                if (bestUci && typeof bestUci === 'string') {
                    let originalUci = bestUci; // Keep original for logging/comparison
                    let corrected = false;
                    switch (bestUci) {
                        case 'e1h1': bestUci = 'e1g1'; corrected = true; break; // White Kingside
                        case 'e1a1': bestUci = 'e1c1'; corrected = true; break; // White Queenside
                        case 'e8h8': bestUci = 'e8g8'; corrected = true; break; // Black Kingside
                        case 'e8a8': bestUci = 'e8c8'; corrected = true; break; // Black Queenside
                        default: break; // Not a known non-standard castling UCI
                    }

                    if (corrected) {
                        // Log the correction clearly for debugging
                        console.warn(`Corrected non-standard Lichess castling UCI from '${originalUci}' to '${bestUci}'`);
                        moveSource = "Lichess (Corrected)"; // Update source for better context later
                    }
                }
                // --- *** END FIX *** ---

            } catch (error) {
                console.error("Error fetching from Lichess Explorer:", error);
                bestUci = null; // Ensure UCI is null if fetch fails
            }
        } else {
            console.log("Skipping Lichess, continueStockfish flag is true.");
            moveSource = "Stockfish"; // Assume Stockfish if skipping Lichess
        }

        // --- Handle Lichess Failure / Ask for Stockfish Fallback ---
        // If Lichess provided no move (or it was corrected to null implicitly above if invalid format?)
        // OR if Lichess fetch failed, OR if Lichess was skipped initially via continueStockfish flag...
        // Check if we need to ask the user about using Stockfish.
        if (!bestUci && !continueStockfish && !openContinueDialog) {
            // Only open dialog if:
            // 1. We don't have a move yet (`!bestUci`)
            // 2. We haven't already agreed to use Stockfish (`!continueStockfish`)
            // 3. The dialog isn't already open (`!openContinueDialog`)
            console.log("Lichess data exhausted, failed, or returned invalid move; opening dialog.");
            setPendingGame(gameInstance); // Store game state for when dialog closes
            setOpenContinueDialog(true); // Show the dialog
            return; // Stop execution here, wait for user input from dialog
        }

        // --- Use Stockfish if needed ---
        // This block runs if:
        // - Lichess fetch failed/returned null initially AND user confirmed Stockfish fallback via dialog OR continueStockfish was already true.
        // - Lichess was skipped because continueStockfish was true from the start.
        if (!bestUci) {
            // If we still don't have a UCI, it means we need to use Stockfish
            moveSource = "Stockfish";
            console.log("Falling back to Stockfish for bot move...");
            try {
                // Use the dedicated function to get a fresh move from Stockfish
                bestUci = await getFreshStockfishMove(currentBotFen, stockfishRef);
                // console.log("Stockfish fresh move returned UCI:", bestUci); // Debug if needed
                // Stockfish *should* always return standard UCI, no correction needed here.
            } catch (error) {
                console.error("Error getting fresh Stockfish move:", error);
                bestUci = null; // Ensure null on error/timeout
            }
        }

        // --- Move Validation and Final Random Fallback ---
        let validatedMoveObject = null; // To store the full move object from chess.js

        // Validate the UCI if we have one (either corrected Lichess or Stockfish)
        if (bestUci && typeof bestUci === 'string' && bestUci.length >= 4) {
            const tempGameForValidation = new Chess(currentBotFen); // Use the correct FEN
            const legalMovesVerbose = tempGameForValidation.moves({ verbose: true });

            // Find the move object corresponding to the (potentially corrected) standard UCI string.
            validatedMoveObject = legalMovesVerbose.find(m =>
                   m.uci === bestUci || // Direct UCI match (e.g., e2e4, e7e8q, e8g8)
                   (m.from + m.to + (m.promotion || '')) === bestUci // Match constructed UCI (mainly for promotions)
            );

            if (!validatedMoveObject) {
                // This means the move (even if corrected) is illegal in the position (e.g., castling through check)
                // Or Stockfish somehow returned an illegal move (highly unlikely).
                const warningMsg = `Move '${bestUci}' from ${moveSource} is NOT legal in FEN ${currentBotFen}. Discarding.`;
                console.warn(warningMsg);
                // Trigger the developer warning toast
                setInvalidMoveDetails(`Invalid Bot Move: ${bestUci} (${moveSource})`);
                setShowInvalidMoveToast(true);
                bestUci = null; // Discard the invalid move
            } else {
                 // If validated, prefer the official UCI from chess.js move object if available
                 bestUci = validatedMoveObject.uci || bestUci;
                 console.log(`Validated move '${bestUci}' (SAN: ${validatedMoveObject.san}) from ${moveSource}.`);
            }
        } else if (bestUci) {
             // Handle cases where bestUci is somehow not a string or too short after all attempts
             console.warn(`Received potentially invalid UCI format: '${bestUci}' from ${moveSource}. Discarding.`);
             setInvalidMoveDetails(`Invalid Bot Move Format: ${bestUci} (${moveSource})`);
             setShowInvalidMoveToast(true);
             bestUci = null; // Discard invalid format
        }

        // --- Final Random Fallback ---
        // If we *still* don't have a valid UCI after Lichess, correction, Stockfish, and validation
        if (!bestUci) {
            moveSource = "Random"; // Update source to reflect this fallback
            console.warn(`No valid move from ${continueStockfish ? 'Stockfish' : 'Lichess/Stockfish'}. Attempting random legal move.`);
            const tempGameForRandom = new Chess(currentBotFen); // Use correct FEN
            const legalMoves = tempGameForRandom.moves({ verbose: true }); // Get available moves

            if (legalMoves.length > 0) {
                const randomMove = legalMoves[Math.floor(Math.random() * legalMoves.length)];
                // Use the official UCI from the randomly selected move object
                bestUci = randomMove.uci || (randomMove.from + randomMove.to + (randomMove.promotion || ""));
                validatedMoveObject = randomMove; // The randomly picked move is inherently valid
                console.log("Using random fallback move:", bestUci, `(SAN: ${validatedMoveObject.san})`);
            } else {
                // This state should ideally not be reachable if game over checks are correct
                console.error("Bot has no legal moves, but game is not marked as over. FEN:", currentBotFen);
                setIsBotThinking(false); // Stop thinking
                setIsCalculating(false);
                // Perhaps force a game over check or display an error?
                return; // Exit the function
            }
        }

        // --- Make the Chosen Bot Move ---
        // At this point, bestUci should hold a validated UCI string, and validatedMoveObject might hold the corresponding move object.
        console.log(`Bot (${moveSource}) playing move: ${bestUci} (SAN: ${validatedMoveObject?.san})`);
        const newGame = new Chess(currentBotFen); // Create new instance from the state bot evaluated

        // Use the validated move *object* if available (safer), otherwise use the UCI string.
        // Using the move object directly handles promotions and complex cases correctly.
        const moveResult = validatedMoveObject ? newGame.move(validatedMoveObject) : newGame.move(bestUci); // Prefer move object

        if (moveResult) {
            // Update game state after successful bot move
            const botMoveFen = newGame.fen();
            setChess(newGame);
            setFen(botMoveFen); // <-- This triggers passive evaluation for player's turn via useEffect
            setMoveHistory(prev => [...prev, moveResult]); // Add bot's full move object to history

             // Check for game over immediately after bot moves
              if (newGame.isGameOver()) {
                  console.log("Game Over (after bot move):", newGame.isCheckmate() ? "Checkmate" : newGame.isStalemate() ? "Stalemate" : newGame.isDraw() ? "Draw" : "Other");
                  setFeedbackText(newGame.isCheckmate() ? "CHECKMATE!" : "GAME OVER");
                  setFeedbackEmoji(newGame.isCheckmate() ? "💀" : "🏁"); // Bot wins = skull? Player wins = trophy
                  setShowFeedback(true);
                  if (feedbackTimeoutRef.current) clearTimeout(feedbackTimeoutRef.current); // Don't auto-hide
              }

        } else {
            // This should ideally not happen if validation and random move logic is correct
            console.error(`CRITICAL: Bot failed to make validated/random move: '${bestUci}' from FEN: ${currentBotFen}. Source: ${moveSource}. Move Object:`, validatedMoveObject);
            // Consider stopping the run or showing a critical error state
        }

        // Bot move process finished
        setIsBotThinking(false);
        setIsCalculating(false); // Ensure calculating state is also reset
        setPendingGame(null); // Clear pending game state from dialog
    };


    // Memoized Square Renderer (If using badges directly on squares)
    // const SquareRenderer = forwardRef((props, ref) => (
    //  <CustomSquareRenderer {...props} badgesMapping={moveBadges} ref={ref} />
    // ));

    // Combine and memoize arrows for display
    const displayedArrows = useMemo(() => {
        const currentMemory = memoryMoves[fen] || [];
        const persistentCustom = customArrows[fen] || [];
        // Combine and de-duplicate based on from/to squares for the current FEN
        const combinedMap = new Map();
        // Add persistent arrows first
        persistentCustom.forEach(m => combinedMap.set(`${m.from}-${m.to}`, [m.from, m.to, m.color]));
        // Memory moves overwrite persistent ones for the same squares if they exist
        currentMemory.forEach(m => combinedMap.set(`${m.from}-${m.to}`, [m.from, m.to, m.color]));
        return Array.from(combinedMap.values());
    }, [fen, memoryMoves, customArrows]); // Recompute when fen or arrow states change


    // --- Render ---
    return (
        <ThemeProvider theme={theme}> {/* Apply the theme */}
            <CssBaseline /> {/* Normalize styles */}
            <Box sx={{ background: theme.palette.background.default, minHeight: "100vh", pb: 4, color: theme.palette.text.primary }}>
                {/* --- AppBar --- (Styling via theme) */}
                <AppBar position="sticky">
                    <Toolbar>
                        <Typography variant="h6" sx={{ fontWeight: "bold", flexGrow: 1, textShadow: `1px 1px 3px rgba(0,0,0,0.5)` }}>
                            🔥 Firechess Rizzloaded 🔥
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Container maxWidth="xl" sx={{ mt: 2, overflow: "hidden" }}>
                    <Grid container spacing={2}>
                        {/* --- Left Column: Board, Controls, Feedback --- */}
                        <Grid item xs={12} md={7} lg={8}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>

                                {/* --- Fire Meter (Enhanced) --- */}
                               <Box sx={{ pt: { xs: 0, md: '0px' } }}> {/* Align roughly with board top */}
                                    <Box
                                        sx={{
                                            width: "30px",
                                            height: { xs: '200px', sm: '300px', md: `${boardWidth}px` }, // Match board height on medium+ screens
                                            maxHeight: '480px',
                                            backgroundColor: theme.palette.fireMeter.background,
                                            borderRadius: "6px",
                                            position: "relative",
                                            overflow: "hidden",
                                            boxShadow: "inset 0 0 8px rgba(0,0,0,0.6)",
                                            border: `2px solid ${isFeverMode ? theme.palette.fireMeter.feverGradient.split(',')[0].trim() : 'rgba(255,255,255,0.1)'}`,
                                            animation: isFeverMode ? `${pulseGlow} 1.2s infinite ease-in-out` : "none",
                                            transition: 'border 0.3s ease, height 0.5s ease', // Added height transition
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: "absolute", bottom: 0, width: "100%",
                                                height: `${fireMeter}%`,
                                                background: isFeverMode ? theme.palette.fireMeter.feverGradient : theme.palette.fireMeter.gradient,
                                                transition: "height 0.5s cubic-bezier(0.25, 1, 0.5, 1), background 0.4s linear",
                                                borderRadius: "4px",
                                            }}
                                        />
                                        <Typography
                                            variant="caption" component="div"
                                            sx={{
                                                position: "absolute", bottom: `calc(${fireMeter}% + 4px)`, width: "100%",
                                                textAlign: "center", color: fireMeter > 50 ? theme.palette.common.black : theme.palette.common.white, // Contrast text
                                                fontWeight: "bold", zIndex: 1,
                                                textShadow: fireMeter > 50 ? "none" : "1px 1px 2px rgba(0,0,0,0.8)",
                                                fontSize: "0.7rem", transition: "bottom 0.5s cubic-bezier(0.25, 1, 0.5, 1), color 0.3s linear",
                                                display: fireMeter > 5 ? "block" : "none"
                                            }}
                                        >
                                            {Math.round(fireMeter)}%
                                        </Typography>
                                    </Box>
                                </Box>

                                {/* --- Chessboard Area (with loading/feedback overlays) --- */}
                                <Box sx={{ position: "relative", width: "100%", maxWidth: `${FIXED_BOARD_WIDTH}px`, margin: "0 auto" }}>
                                    {/* Loading/Thinking/Evaluating Overlay */}
                                    {(isCalculating || isBotThinking || isEvaluating) && (
                                        <Box sx={{
                                            position: "absolute", top: 0, left: 0, right: 0, bottom: 0,
                                            backgroundColor: "rgba(0, 0, 0, 0.55)", display: "flex",
                                            flexDirection: 'column',
                                            justifyContent: "center", alignItems: "center", zIndex: 10,
                                            borderRadius: "8px", color: theme.palette.text.primary,
                                            backdropFilter: 'blur(2px)', // Optional blur effect
                                        }}>
                                            <CircularProgress size={40} color="inherit" />
                                            <Typography sx={{ mt: 1.5, fontWeight: 'bold', fontSize: '0.9rem' }}>
                                                {isBotThinking ? "Bot is Cooking..." : (isEvaluating || isCalculating) ? "Analyzing..." : "Processing..."}
                                            </Typography>
                                        </Box>
                                    )}

                                    {/* --- Move Feedback Popup --- */}
                                     <Grow in={showFeedback} timeout={400}>
                                          <Paper elevation={6} sx={{
                                               position: 'absolute', top: '50%', left: '50%',
                                               transform: 'translate(-50%, -50%)', zIndex: 15,
                                               p: 2, borderRadius: '12px', textAlign: 'center',
                                               background: `rgba(30, 30, 30, 0.85)`, // Darker translucent background
                                               border: `3px solid ${theme.palette.moveQuality[lastMoveRating] || theme.palette.text.secondary}`,
                                               minWidth: '160px', boxShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
                                               backdropFilter: 'blur(3px)', // Optional blur
                                               animation: lastMoveRating === 'Blunder' ? `${shake} 0.5s cubic-bezier(.36,.07,.19,.97) both` : 'none',
                                               pointerEvents: 'none', // Prevent clicks on the feedback
                                           }}>
                                               <Typography variant="h3" sx={{ mb: 0.5 }}>{feedbackEmoji}</Typography>
                                               <Typography variant="h6" sx={{ color: theme.palette.moveQuality[lastMoveRating] || theme.palette.text.primary, fontWeight: 'bold' }}>
                                                    {feedbackText}
                                               </Typography>
                                               {/* Score change text (now part of the central popup) */}
                                               {scoreChange !== 0 && (
                                                    <Typography variant="body1" sx={{ color: scoreChange > 0 ? theme.palette.moveQuality.Excellent : theme.palette.moveQuality.Blunder, fontWeight: 'bold', mt: 0.5}}>
                                                         {scoreChange > 0 ? `+${scoreChange}` : scoreChange} PTS
                                                   </Typography>
                                               )}
                                           </Paper>
                                      </Grow>

                                    {/* --- The Chessboard Component --- */}
                                    <Chessboard
                                        id="main-board"
                                        position={fen}
                                        onPieceDrop={onDrop}
                                        boardWidth={boardWidth}
                                        arePiecesDraggable={!isCalculating && !isBotThinking && !chess.isGameOver()}
                                        customArrows={displayedArrows}
                                        // customSquare={SquareRenderer} // Uncomment if needed and CustomSquareRenderer is implemented
                                        customBoardStyle={{ borderRadius: "10px", boxShadow: `0 8px 25px rgba(0, 0, 0, 0.6)` }}
                                        customDarkSquareStyle={{ backgroundColor: theme.palette.board.dark }}
                                        customLightSquareStyle={{ backgroundColor: theme.palette.board.light }}
                                        arrowThickness={6} // Thicker arrows
                                        animationDuration={200} // Smooth piece animation
                                    />
                                </Box> {/* End Chessboard Area */}
                            </Box> {/* End Flex Container for Fire Meter + Board */}

                            {/* --- Control Buttons --- */}
                             <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1, justifyContent: "center", alignItems: "center" }}>
                                 {/* Quick Eval button removed as passive evaluation is always running */}
                                 <Button variant="outlined" size="small" onClick={() => { console.warn("Undo functionality not implemented"); /* Need to implement game state rewind */}} disabled={isCalculating || isBotThinking || moveHistory.length < 1}>
                                     Oops (Undo)
                                 </Button>
                                 <Button variant="contained" color="primary" size="large" onClick={newRun} disabled={isCalculating || isBotThinking}>
                                     New Run 🔥
                                 </Button>
                             </Box>
                        </Grid> {/* End Left Column */}


                        {/* --- Right Column: Info, Score, Explorer --- */}
                         <Grid item xs={12} md={5} lg={4} sx={{ minWidth: { md: "320px" }, maxWidth: { md: "400px" } }}>
                             <Box sx={{ maxHeight: { md: "calc(100vh - 80px)" }, overflowY: "auto", pr: { md: 1 }, scrollbarWidth: "thin", scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.background.paper}`, '&::-webkit-scrollbar': { width: '8px' }, '&::-webkit-scrollbar-track': { background: theme.palette.background.paper, borderRadius: '4px' }, '&::-webkit-scrollbar-thumb': { backgroundColor: theme.palette.secondary.main, borderRadius: '4px', border: `2px solid ${theme.palette.background.paper}` } }}>

                                 {/* --- Run Score (Fixed Animation) --- */}
                                 <Paper elevation={4} sx={{ p: 2, mb: 2, textAlign: 'center', background: theme.palette.background.paper, borderLeft: `5px solid ${isFeverMode ? theme.palette.fireMeter.feverGradient.split(',')[0].trim() : theme.palette.secondary.main}` }}>
                                     <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>
                                         Run Score {isFeverMode ? '🚀 FEVER!' : ''}
                                     </Typography>
                                     <Box sx={{ position: 'relative', display: 'inline-block', minHeight: '48px' /* Prevent layout shift */ }}>
                                         <Typography variant="h3" sx={{ fontWeight: 'bold', display: 'inline' }}>{runScore}</Typography>
                                         {/* Score Change Animation */}
                                         {scoreChange !== 0 && showFeedback /* Only show animated score next to main score *while* feedback popup is visible */ && (
                                              <Typography
                                                 variant="h6"
                                                 sx={{
                                                     position: 'absolute', top: '-10px', left: '105%', // Position relative to the score number
                                                     fontWeight: 'bold',
                                                     animation: `${scoreChange > 0 ? scoreUp : scoreDown} 0.8s ease-out forwards`,
                                                     whiteSpace: 'nowrap', pointerEvents: 'none', // Prevent interaction
                                                 }}
                                             >
                                                 {scoreChange > 0 ? `+${scoreChange}` : scoreChange}
                                             </Typography>
                                         )}
                                     </Box>
                                 </Paper>

                                 {/* --- Rating Filter --- */}
                                 <Paper elevation={3} sx={{ p: 1.5, mb: 2 }}>
                                     <Typography variant="h6" component="h2" gutterBottom>Rating Filter</Typography>
                                     <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                         <ToggleButtonGroup value={selectedRatings} onChange={handleRatingToggle} aria-label="rating ranges" size="small" sx={{ flexWrap: "wrap", gap: 0.5, justifyContent: 'center' }}>
                                             {["400", "1000", "1200", "1400", "1600", "1800", "2000", "2200", "2500"].map((rv) => (
                                                 <ToggleButton key={rv} value={rv} aria-label={`${rv}`} sx={{ flexGrow: 1, px: 1 }}>{rv}</ToggleButton>
                                             ))}
                                         </ToggleButtonGroup>
                                     </Box>
                                 </Paper>

                                 {/* --- Engine Evaluation --- */}
                                 <Paper elevation={3} sx={{ p: 1.5, mb: 2 }}>
                                     <Typography variant="h6" component="h2" gutterBottom>Engine Eval</Typography>
                                     <Box sx={{ display: "flex", alignItems: "center", minHeight: "2.5em", mb: 1 }}>
                                         {(isEvaluating && engineEval === null) ? (
                                             <>
                                                 <CircularProgress size={20} sx={{ mr: 1 }} color="secondary"/>
                                                 <Typography variant="body1" sx={{ fontStyle: 'italic' }}>Analyzing...</Typography>
                                             </>
                                         ) : engineEval !== null ? (
                                             <Typography variant="h5" sx={{ fontWeight: "bold", mr: 1, color: engineEval > 0.2 ? theme.palette.moveQuality.Excellent : engineEval < -0.2 ? theme.palette.moveQuality.Mistake : theme.palette.text.primary }}>
                                                 {engineEval >= 0 ? '+' : ''}{engineEval.toFixed(2)}
                                             </Typography>
                                         ) : (
                                             <Typography variant="body1" sx={{ mr: 1, color: theme.palette.text.secondary }}>N/A</Typography>
                                         )}
                                          {(isEvaluating && engineEval !== null) && <CircularProgress size={16} sx={{ ml: 0.5 }} color="inherit"/>}
                                         {isCalculating && <Typography variant="caption" sx={{ fontStyle: 'italic', ml: 1 }}>(Calculating...)</Typography>}
                                     </Box>
                                     {pvMoves && pvMoves.length > 0 && (
                                         <Box sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word", mt: 0.5, maxHeight: '80px', overflowY: 'auto', background: 'rgba(255,255,255,0.05)', p:1, borderRadius: '4px' }}>
                                             <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'monospace', fontSize: '0.8rem', lineHeight: 1.4 }}>
                                                 {`PV: ${pvMoves.join(" ")}`}
                                             </Typography>
                                         </Box>
                                     )}
                                 </Paper>

                                 {/* --- Move History --- */}
                                 <Paper elevation={3} sx={{ p: 1.5, mb: 2 }}>
                                     <Typography variant="h6" component="h2" gutterBottom>Move History</Typography>
                                     <Box sx={{
                                         overflowWrap: "break-word", wordBreak: "break-all", maxHeight: "120px", overflowY: "auto",
                                         fontFamily: "monospace", lineHeight: 1.6, fontSize: "0.9rem", p: 1, background: 'rgba(255,255,255,0.05)', borderRadius: '4px' }}>
                                         {moveHistory.length === 0 ? "No moves yet..." : moveHistory.map((move, index) => { const moveNum = Math.floor(index / 2) + 1; const prefix = index % 2 === 0 ? `${moveNum}. ` : ""; return <span key={`${index}-${move.san}`}>{prefix}{move.san || move.uci || `${move.from}${move.to}`} </span>; })}
                                     </Box>
                                 </Paper>

                                 {/* --- Lichess Explorer --- */}
                                 <Paper elevation={3} sx={{ p: 1.5, mb: 2 }}>
                                     <Typography variant="h6" component="h2" gutterBottom>Lichess Explorer</Typography>
                                     <Box sx={{ minHeight: "150px", position: 'relative' }}>
                                         {explorerLoading && ( <Box sx={{ position: 'absolute', top: '10px', left: '10px', display: "flex", alignItems: "center" }}> <CircularProgress size={20} sx={{ mr: 1 }} color="secondary"/> <Typography variant="body2">Loading...</Typography> </Box> )}
                                         {explorerError && !explorerLoading && ( <Typography variant="body2" color="error" sx={{p: 1}}> Error: {explorerError.length > 100 ? explorerError.substring(0, 100) + '...' : explorerError} </Typography> )}
                                         {explorerData && !explorerLoading && !explorerError && (
                                             <>
                                                  <Typography variant="body2" sx={{ mb: 0.5 }}> <strong title={`W: ${explorerData.white} / D: ${explorerData.draws} / L: ${explorerData.black}`}> Total games: </strong>{" "} {explorerData.white + explorerData.draws + explorerData.black} </Typography>
                                                  {explorerData.opening && ( <Typography variant="body2" sx={{ mb: 1, fontStyle: 'italic' }}> <strong>Opening:</strong> {explorerData.opening.name} ({explorerData.opening.eco}) </Typography> )}

                                                  {combinedTableMoves.length > 0 ? (
                                                      <Box sx={{ maxHeight: "200px", overflowY: "auto" }}>
                                                          <Table size="small" stickyHeader>
                                                              <TableHead>
                                                                  <TableRow>
                                                                      <TableCell sx={{ p: 0.5, fontWeight: "bold", background: theme.palette.background.paper }}>Move</TableCell>
                                                                      <TableCell sx={{ p: 0.5, fontWeight: "bold", background: theme.palette.background.paper, textAlign: 'right' }}>Games</TableCell>
                                                                      <TableCell sx={{ p: 0.5, fontWeight: "bold", background: theme.palette.background.paper, textAlign: 'right' }}>Win %</TableCell>
                                                                      <TableCell sx={{ p: 0.5, fontWeight: "bold", background: theme.palette.background.paper, textAlign: 'right' }}>Draw %</TableCell>
                                                                      <TableCell sx={{ p: 0.5, fontWeight: "bold", background: theme.palette.background.paper, textAlign: 'right' }}>Loss %</TableCell>
                                                                  </TableRow>
                                                              </TableHead>
                                                              <TableBody>
                                                                  {combinedTableMoves.slice(0, 8).map((move) => ( // Limit displayed moves
                                                                      <TableRow key={move.uci} hover sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)'} }}>
                                                                          <TableCell sx={{ p: 0.5, overflowWrap: "break-word", wordBreak: "break-all", fontWeight: "500" }}> {move.san} </TableCell>
                                                                          <TableCell sx={{ p: 0.5, textAlign: 'right' }}>{move.totalGames || "N/A"}</TableCell>
                                                                          <TableCell sx={{ p: 0.5, whiteSpace: "nowrap", fontSize: "0.75rem", textAlign: 'right', color: theme.palette.moveQuality.Excellent }}> {move.winPct !== null ? `${move.winPct.toFixed(0)}%` : "N/A"} </TableCell>
                                                                          <TableCell sx={{ p: 0.5, whiteSpace: "nowrap", fontSize: "0.75rem", textAlign: 'right', color: theme.palette.text.secondary }}> {move.drawPct !== null ? `${move.drawPct.toFixed(0)}%` : "N/A"} </TableCell>
                                                                          <TableCell sx={{ p: 0.5, whiteSpace: "nowrap", fontSize: "0.75rem", textAlign: 'right', color: theme.palette.moveQuality.Blunder }}> {move.lossPct !== null ? `${move.lossPct.toFixed(0)}%` : "N/A"} </TableCell>
                                                                      </TableRow>
                                                                  ))}
                                                              </TableBody>
                                                          </Table>
                                                      </Box>
                                                    ) : ( <Typography variant="body2" sx={{mt: 2}}>No Lichess moves found for this position/rating.</Typography> )}
                                             </>
                                         )}
                                         {!explorerData && !explorerLoading && !explorerError && ( <Typography variant="body2" sx={{mt: 2}}>No explorer data available for this position.</Typography> )}
                                     </Box>
                                 </Paper>
                             </Box> {/* End Scrollable Right Column */}
                         </Grid> {/* End Right Column */}
                    </Grid> {/* End Main Grid */}
                </Container>

                {/* --- Stockfish Fallback Dialog --- */}
                <Dialog
                    open={openContinueDialog}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') { // Prevent closing on backdrop click
                            console.log("Stockfish fallback dialog closed by user (Cancel).");
                            setOpenContinueDialog(false);
                            setPendingGame(null); // Clear pending state
                            setIsBotThinking(false);
                            setIsCalculating(false);
                            if (!chess.isGameOver() && stockfishRef.current) {
                                 stockfishRef.current.postMessage("stop");
                                 stockfishRef.current.postMessage(`position fen ${fen}`);
                                 stockfishRef.current.postMessage("go depth 16");
                                 setIsEvaluating(true);
                            }
                        }
                    }}
                    disableEscapeKeyDown // Prevent closing with escape key
                >
                     <DialogTitle sx={{fontWeight: 'bold'}}>Lichess Data Exhausted!</DialogTitle>
                     <DialogContent>
                         <Typography>No common human moves found in the Lichess database for these ratings.</Typography>
                         <Typography sx={{ mt: 1 }}>Continue the run against pure Stockfish? (It won't follow human lines)</Typography>
                     </DialogContent>
                     <DialogActions>
                         <Button onClick={() => {
                              setOpenContinueDialog(false);
                              setPendingGame(null);
                              setIsBotThinking(false);
                              setIsCalculating(false);
                              console.log("User chose to end run instead of using Stockfish fallback.");
                              setFeedbackText("Run Ended");
                              setFeedbackEmoji("🛑");
                              setShowFeedback(true);
                              if (feedbackTimeoutRef.current) clearTimeout(feedbackTimeoutRef.current);
                         }} color="secondary"> Nah, End Run </Button>
                         <Button onClick={() => {
                              console.log("User chose to continue with Stockfish fallback.");
                              setContinueStockfish(true);
                              setOpenContinueDialog(false);
                              if (pendingGame) {
                                  console.log("Retrying bot move with Stockfish flag set.");
                                  doBotMove(pendingGame);
                              } else {
                                  console.error("Pending game state was lost when confirming Stockfish fallback.");
                                  setIsBotThinking(false);
                              }
                         }} color="primary" variant="contained" autoFocus> Yes (Unleash Stockfish) </Button>
                     </DialogActions>
                 </Dialog>

                 {/* --- Invalid Move Toast --- */}
                 <Snackbar
                     open={showInvalidMoveToast}
                     autoHideDuration={6000} // Hide after 6 seconds
                     onClose={() => setShowInvalidMoveToast(false)}
                     anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Position
                 >
                     <Alert
                         onClose={() => setShowInvalidMoveToast(false)}
                         severity="warning" // Use warning severity
                         sx={{ width: '100%' }}
                         variant="filled"
                     >
                         {invalidMoveDetails}
                     </Alert>
                 </Snackbar>

            </Box> {/* End Root Box */}
        </ThemeProvider>
    );
}

export default App;