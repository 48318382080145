// src/theme.js
import { createTheme } from "@mui/material/styles";

// Define your core colors
const coreColors = {
  primary: '#d32f2f', // Deep Red (User's Preference)
  secondary: '#ff9800', // Orange (User's Preference)
  highlight: '#ffeb3b', // Yellowish (User's Preference for text.secondary originally)
  white: '#ffffff',
  black: '#000000',
  darkBg: '#121212',
  paperBg: '#1e1e1e',
  lightGreyText: '#E0E0E0', // Adding a standard light grey for text
  mediumGreyText: '#A0A0A0', // Adding a standard medium grey
};

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: coreColors.primary,
      contrastText: coreColors.white, // White text on deep red buttons
    },
    secondary: {
      main: coreColors.secondary,
      contrastText: coreColors.black, // Black text might offer better contrast on orange buttons
    },
    background: {
      default: coreColors.darkBg,
      paper: coreColors.paperBg,
    },
    text: {
      primary: coreColors.white, // Keep primary text white
      secondary: coreColors.mediumGreyText, // Use medium grey for less emphasis text
      highlight: coreColors.highlight, // Keep the user's yellow accessible for specific highlights
    },
    // --- Custom Color Categories (Adapted to Red/Orange/Yellow) ---
    fireMeter: {
      background: '#333',
      // Gradient goes Yellow -> Orange -> Red
      gradient: `linear-gradient(180deg, ${coreColors.highlight}, ${coreColors.secondary}, ${coreColors.primary})`,
      // Fever mode pulses with bright yellow
      feverGradient: `linear-gradient(180deg, #fff176, ${coreColors.highlight})`, // Brighter yellow -> yellow
    },
    board: {
      // Warm brown tones to complement red/orange
      light: '#A1887F', // Muted Brown-Grey
      dark: '#5D4037',  // Dark Brown
    },
    arrow: { // Base colors (helper.js logic still applies specific rgba)
      best: 'rgba(0, 255, 127, 0.9)', // Keep green for 'good' arrows as it's universally understood
      blunder: 'rgba(255, 69, 0, 0.9)', // Keep red/orange for 'bad' arrows
    },
    moveQuality: { // Colors for feedback text/backgrounds, adapted
      Brilliant: '#fff176', // Bright Yellow
      Great: '#ffb74d',     // Light Orange (Lighter than secondary)
      Excellent: '#ffcc80', // Pale Orange/Yellow
      Good: '#ffee58',      // Standard Yellow (Slightly brighter than highlight)
      Best: coreColors.lightGreyText, // Neutral Light Grey
      Inaccuracy: coreColors.highlight, // User's original Yellow
      Mistake: '#fb8c00',   // Darker Orange (Darker than secondary)
      Blunder: '#e53935',    // Brighter Red (Brighter than primary for pop)
    }
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif', // Modern font
    h6: {
      fontWeight: 700, // Bolder headings
    },
    button: {
        textTransform: 'none', // Less formal buttons
        fontWeight: 600,
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none', // Ensure dark mode paper consistency
          border: '1px solid rgba(255, 255, 255, 0.12)', // Subtle border, slightly more visible
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
          root: {
              '&.Mui-selected': {
                  // Use Secondary (Orange) for selected filter buttons
                  backgroundColor: coreColors.secondary,
                  color: coreColors.black, // Black text for contrast on orange
                  '&:hover': {
                      backgroundColor: '#ffa726', // Slightly lighter orange hover
                  }
              },
              border: `1px solid ${coreColors.mediumGreyText}`, // Use grey border
              color: coreColors.lightGreyText, // Use light grey for non-selected text
          }
      }
    },
    MuiButton: {
        styleOverrides: {
            // Define hover states explicitly if needed
            containedPrimary: { // Red button
                '&:hover': {
                    backgroundColor: '#e53935', // Slightly brighter red on hover
                }
            },
            containedSecondary: { // Orange button
                '&:hover': {
                    backgroundColor: '#ffa726', // Lighter orange on hover
                }
            }
        }
    },
    MuiAppBar: {
        styleOverrides: {
            root: {
                 // Gradient from Secondary (Orange) to Primary (Red)
                 background: `linear-gradient(90deg, ${coreColors.secondary} 0%, ${coreColors.primary} 100%)`,
                 // Glow effect using the AppBar colors
                 boxShadow: `0px 4px 20px rgba(255, 152, 0, 0.4), 0px 2px 10px rgba(211, 47, 47, 0.3)`,
            }
        }
    }
  },
});

export default theme;