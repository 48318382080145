import { Chess } from "chess.js";

export const initializeStockfish = (stockfishRef, setEvaluation, setPvMoves, game) => {
  // Terminate any old worker
  if (stockfishRef.current) {
    stockfishRef.current.terminate();
  }

  const stockfishPath = `${process.env.PUBLIC_URL}/stockfish-nnue-16.js`;
  stockfishRef.current = new Worker(stockfishPath, { type: "module" });

  stockfishRef.current.onmessage = (event) => {
    const message = event.data;
    console.log("[Stockfish init message]", message);

    // We'll parse "info depth" lines, looking for "score cp" or "score mate"
    if (message.includes("info depth") && (message.includes("score cp") || message.includes("score mate"))) {
      const parts = message.split(" ");
      const depthIndex = parts.indexOf("depth");
      let depth = null;
      if (depthIndex !== -1) {
        depth = parts[depthIndex + 1];
      }

      // Check if 'score cp' or 'score mate'
      let score = null;
      let mate = null;

      const cpIndex = parts.indexOf("cp");
      if (cpIndex !== -1) {
        score = parseInt(parts[cpIndex + 1], 10) / 100;
        if (game.turn() === "b") score *= -1;
      }
      const mateIndex = parts.indexOf("mate");
      if (mateIndex !== -1) {
        mate = parseInt(parts[mateIndex + 1], 10);
        // if mate>0 => large positive, if mate<0 => large negative
        if (mate > 0) {
          score = 100;
        } else {
          score = -100;
        }
      }

      if (score !== null) {
        // setEvaluation(`Score: ${score} (Depth: ${depth})`);
        setEvaluation(score);
      }

      // If there's a PV line => parse it out
      const pvIndex = parts.indexOf("pv");
      if (pvIndex !== -1) {
        const pv = parts.slice(pvIndex + 1);
        setPvMoves(pv);
      }
    }
  };

  // basic uci init
  stockfishRef.current.postMessage("uci");
};

// Evaluate position with a separate worker, 2-second search
export const evaluatePositionSequentially = (fen) => {
  return new Promise((resolve) => {
    const stockfishPath = `${process.env.PUBLIC_URL}/stockfish-nnue-16.js`;
    const stockfishWorker = new Worker(stockfishPath, { type: "module" });

    let resolved = false;
    let latestScore = null; 
    let latestMate = null;

    const handleStockfishMessage = (event) => {
      const message = event.data;
      // console.log("[Stockfish eval message]", message);

      if (message.includes("info")) {
        const parts = message.split(" ");
        const cpIndex = parts.indexOf("cp");
        const mateIndex = parts.indexOf("mate");

        if (cpIndex !== -1) {
          latestScore = parseInt(parts[cpIndex + 1], 10) / 100;
        } else if (mateIndex !== -1) {
          latestMate = parseInt(parts[mateIndex + 1], 10);
        }
      }
    };

    stockfishWorker.addEventListener("message", handleStockfishMessage);

    stockfishWorker.postMessage("uci");
    stockfishWorker.postMessage(`position fen ${fen}`);
    stockfishWorker.postMessage("go movetime 2000");

    setTimeout(() => {
      if (!resolved) {
        resolved = true;
        let finalScore = 0;
        if (latestMate !== null) {
          finalScore = latestMate > 0 ? 100 : -100; 
        } else if (latestScore !== null) {
          finalScore = latestScore;
        }
        stockfishWorker.removeEventListener("message", handleStockfishMessage);
        stockfishWorker.terminate();
        resolve(finalScore);
      }
    }, 2500);
  });
};
