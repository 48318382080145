import { Chess } from "chess.js";

// Converts an engine evaluation (in pawn units) to expected points
// using the Chess.com expected points formula.
// Eval score is always from White's perspective.
export const computeExpectedPoints = (evalScore, avgRating) => {
    // avgRating isn't currently used but could be for dynamic curves
    const alpha = 1; // Adjust to steepen/flatten the curve. Higher = steeper.
    // Ensure evalScore is a number, default to 0 if not
    const safeEvalScore = typeof evalScore === 'number' ? evalScore : 0;
    const centipawnEval = safeEvalScore * 100;
    const effectiveDelta = alpha * centipawnEval;
    // Clamp the effectiveDelta to prevent extreme values causing issues with Math.pow
    // e.g., 10^-(large_negative / 400) -> 10^(large_positive) -> Infinity
    // e.g., 10^-(large_positive / 400) -> 10^(large_negative) -> 0
    // Clamping between +/- 800 centipawns (8 pawns) seems reasonable for EP context
    const clampedDelta = Math.max(-800, Math.min(800, effectiveDelta));
    return 1 / (1 + Math.pow(10, -clampedDelta / 400));
};

/**
 * Classifies a chess move based on the change in evaluation (from the player's perspective).
 * @param {number} preExp Expected points before the move (0 to 1, White's perspective).
 * @param {number} postExp Expected points after the move (0 to 1, White's perspective).
 * @param {number} rawDelta Change in raw evaluation (pawn units) from the perspective of the player who moved. Positive = good for player.
 * @returns {string} Classification ('Blunder', 'Mistake', 'Inaccuracy', 'Best', 'Good', 'Excellent', 'Great', 'Brilliant').
 */
export const classifyMoveComposite = (preExp, postExp, rawDelta) => {

    // Ensure inputs are valid numbers
    preExp = typeof preExp === 'number' ? preExp : 0.5;
    postExp = typeof postExp === 'number' ? postExp : 0.5;
    rawDelta = typeof rawDelta === 'number' ? rawDelta : 0;

    // --- CASE 1: Player was clearly winning (preExp > 0.95) ---
    // In winning positions, focus is on maintaining the win efficiently. Big rawDelta gains are less common.
    if (preExp > 0.95) {
        // Did player throw away the win? (Significant drop in expected points)
        if (postExp <= 0.70) return "Blunder";    // Big drop, potentially drawn/lost
        if (postExp <= 0.85) return "Mistake";    // Significant drop, win is harder
        if (postExp < 0.95) return "Inaccuracy"; // Minor drop, less precise, win less certain

        // Player maintained the decisive win (postExp >= 0.95)
        // How good was the move *within* the winning state? (Based on rawDelta)
        // Thresholds adjusted based on user feedback - harder to get top ratings here.
        if (rawDelta >= 2.5) return "Excellent"; // Found a very strong continuation / mate threat etc. (was 2.0)
        if (rawDelta >= 0.3) return "Good";      // Solid move, pressed advantage (was 0.2)
        return "Best";                           // Maintained the win adequately (small gain/loss is fine)

        // NOTE: "Great" and "Brilliant" are intentionally very hard/impossible to get
        // when already in a completely winning position. This prevents "Galaxy Brain"
        // for simple captures or obvious moves in won endgames.
    }

    // --- CASE 2: Player was clearly losing (preExp < 0.05) ---
    // In losing positions, focus is on finding resources or best defense. rawDelta positive means player improved their situation.
    else if (preExp < 0.05) {
        // Did player make their losing situation significantly worse? (postExp drops further - more negative rawDelta)
        // Note: rawDelta is player perspective, so negative rawDelta means eval worsened for them.
        if (rawDelta < -1.5) return "Blunder";  // Made a terrible situation even worse
        if (rawDelta < -0.7) return "Mistake";  // Worsened the position significantly

        // Did player allow the opponent (who was winning) to blunder back? (postExp increases significantly)
        // This reflects well on the player finding a tricky move or the opponent failing.
        if (postExp >= 0.80) return "Brilliant"; // Opponent blundered massively, maybe due to a trap?
        if (postExp >= 0.50) return "Great";     // Turned a lost position into equal/winning
        if (postExp >= 0.25) return "Excellent"; // Found significant counterplay, now only slightly losing/drawn
        if (postExp >= 0.10) return "Good";      // Found some resource, position less lost (was 0.15 rawDelta)

        // Player kept the position losing but didn't worsen it significantly.
        return "Best"; // Default for surviving / holding a lost position without immediate collapse.
    }

    // --- CASE 3: Non-Decisive Position (0.05 <= preExp <= 0.95) ---
    // In relatively balanced positions, both EP change and rawDelta are important.
    else {
        // Focus primarily on rawDelta (player's perspective eval change) for classification.

        // --- Handle Negative rawDelta (Loss in evaluation for the player) ---
        if (rawDelta < -1.5) return "Blunder";    // Huge drop
        if (rawDelta < -0.7) return "Mistake";    // Significant drop
        if (rawDelta < -0.3) return "Inaccuracy"; // Noticeable drop

        // Check if the move resulted in a decisive state change negatively
        // (e.g., went from non-losing 0.1 to clearly losing 0.01 requires postExp check)
        if (preExp > 0.05 && postExp <= 0.05 && rawDelta < -0.5) return "Blunder"; // Transitioned to clearly losing

        // --- Handle Positive or slightly negative rawDelta ---
        // If rawDelta is negative but small (>-0.3), it's not an inaccuracy yet.
        // It might be the "Best" practical move or only option.
        if (rawDelta < 0.05) return "Best";      // Only tiny dip or no gain (Threshold was 0.0)
        if (rawDelta < 0.25) return "Good";      // Slight plus (Threshold was 0.15)
        if (rawDelta < 0.75) return "Excellent"; // Solid gain (Threshold kept)
        if (rawDelta < 1.75) return "Great";     // Significant gain (Threshold kept)
        return "Brilliant";                      // Truly massive gain required (> 1.75 pawns)
    }
};


// Generates a bot move using Lichess explorer.
export const getBotMoveFromLichess = async (fen, selectedRatings) => {
    try {
        const ratingParam =
            selectedRatings.length > 0 ? selectedRatings.join(",") : "1600,1800,2000"; // Default ratings if none selected
        const encodedFen = encodeURIComponent(fen);
        const url = `https://explorer.lichess.ovh/lichess?variant=standard&speeds=blitz,rapid,classical&ratings=${ratingParam}&fen=${encodedFen}`;
        // console.log("Fetching Lichess:", url); // Debugging
        const response = await fetch(url);
        if (!response.ok) {
            const errorText = await response.text();
            console.error("Lichess API Error Response:", errorText);
            throw new Error(`HTTP error: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        // console.log("Lichess Data:", data); // Debugging
        const moves = data.moves;
        if (!moves || moves.length === 0) {
            console.log("No moves found in Lichess explorer for FEN:", fen, "Ratings:", ratingParam);
            return null; // No moves found in explorer
        }

        // Weighted random selection based on game frequency
        let total = 0;
        const weights = moves.map((m) => {
            const w = (m.white || 0) + (m.draws || 0) + (m.black || 0);
            total += w;
            return w;
        });
        // Handle case where total is 0 (no games found, though moves array exists)
        if (total === 0) {
            console.log("Lichess moves found, but total games count is zero.");
            // Fallback: return the first move's UCI if available, otherwise null
            return moves.length > 0 ? moves[0].uci : null;
        }

        const randomVal = Math.random() * total;
        let cumulative = 0;
        let selectedMoveUci = null;
        for (let i = 0; i < moves.length; i++) {
            cumulative += weights[i];
            if (randomVal <= cumulative) {
                selectedMoveUci = moves[i].uci; // Use UCI
                break;
            }
        }
        // Ensure a move was actually selected (should always happen if total > 0)
        if (!selectedMoveUci && moves.length > 0) {
            console.warn("Weighted random selection failed to pick a move, defaulting to first move.");
            selectedMoveUci = moves[0].uci;
        }

        // console.log("Selected Lichess Move UCI:", selectedMoveUci); // Debugging
        return selectedMoveUci; // Should return UCI like 'e2e4', 'e1g1' etc.
    } catch (error) {
        console.error("Error fetching/processing Lichess explorer data:", error);
        return null; // Return null on error
    }
};

// Gets a fresh best move from Stockfish by sending a new evaluation request.
export const getFreshStockfishMove = (fen, stockfishRef) => {
    return new Promise((resolve, reject) => {
        if (!stockfishRef.current) {
            console.warn("Stockfish reference not available for fresh move request.");
            resolve(null); // Keep resolving null for now to match existing fallback logic
            return;
        }

        const timeoutDuration = 7000; // Increased timeout (7 seconds) for Stockfish response
        let timeoutId = null;
        let receivedResponse = false; // Flag to prevent resolving after timeout/error
        let bestMoveFound = null; // Store the best move found

        const listener = (event) => {
            const line = event.data;
            // console.log("Stockfish Raw:", line); // Debugging
            if (typeof line === 'string') {
                // Could potentially extract the first move from PV as a fallback if bestmove fails
                // if (line.includes(" pv ")) { ... }

                // Primarily interested in the final bestmove line
                if (line.startsWith("bestmove")) {
                    if (receivedResponse) return; // Only process the first bestmove received
                    clearTimeout(timeoutId); // Clear timeout on successful response
                    receivedResponse = true;
                    stockfishRef.current.removeEventListener("message", listener);
                    const tokens = line.split(" ");
                    bestMoveFound = tokens[1]; // Should be UCI notation like 'e2e4', 'e1g1' etc.
                    // console.log("Stockfish bestmove found:", bestMoveFound); // Debugging
                    if (bestMoveFound && bestMoveFound !== '(none)') {
                        resolve(bestMoveFound);
                    } else {
                        console.warn("Stockfish returned invalid or no bestmove:", line);
                        resolve(null); // Resolve with null if bestmove is invalid
                    }
                }
            }
        };

        // Timeout handler
        timeoutId = setTimeout(() => {
            if (receivedResponse) return; // Already handled
            receivedResponse = true; // Prevent listener from resolving later
            console.error(`Stockfish bestmove request timed out after ${timeoutDuration}ms for FEN: ${fen}`);
            stockfishRef.current?.removeEventListener("message", listener); // Use optional chaining
            resolve(null); // Resolve null to trigger fallback logic
        }, timeoutDuration);

        try {
            stockfishRef.current.addEventListener("message", listener);
            stockfishRef.current.postMessage("stop"); // Ensure it's not thinking
            stockfishRef.current.postMessage(`position fen ${fen}`);
            // Use a reasonable depth for fallback - skill level is handled by UCI option elsewhere
            stockfishRef.current.postMessage("go depth 18");
            // console.log("Requested Stockfish move for FEN:", fen); // Debugging
        } catch (error) {
            if (receivedResponse) return; // Already handled
            receivedResponse = true;
            clearTimeout(timeoutId);
            console.error("Error posting message to Stockfish:", error);
            stockfishRef.current?.removeEventListener("message", listener);
             resolve(null); // Resolve null to trigger fallback logic
        }
    });
};


// Updated Arrow Colors for more pop
export const getArrowColor = (playerDelta) => {
    // playerDelta is rawDelta (player perspective) - positive is good, negative is bad
    if (playerDelta >= 1.75) return "rgba(0, 255, 0, 0.95)";   // Brilliant - Bright Green
    if (playerDelta >= 0.75) return "rgba(100, 255, 100, 0.9)"; // Great/Excellent - Light Green
    if (playerDelta >= 0.25) return "rgba(173, 255, 47, 0.85)";  // Good - Green-Yellow (Adjusted threshold slightly)
    if (playerDelta > -0.3) return "rgba(255, 255, 150, 0.8)";   // Best/Minor Inaccuracy - Pale Yellow
    if (playerDelta > -0.7) return "rgba(255, 215, 0, 0.8)";   // Inaccuracy - Gold/Orange
    if (playerDelta > -1.5) return "rgba(255, 140, 0, 0.85)";  // Mistake - Dark Orange
    return "rgba(255, 69, 0, 0.9)";                   // Blunder - Red-Orange
};

// NEW function to get arrow color based on the classification string
export const getArrowColorFromClassification = (rating) => {
  switch (rating) {
      case "Brilliant":   return "rgba(0, 255, 0, 0.95)";   // Bright Green
      case "Great":       return "rgba(100, 255, 100, 0.9)"; // Light Green
      case "Excellent":   return "rgba(173, 255, 47, 0.85)"; // Green-Yellow
      case "Good":        return "rgba(220, 255, 100, 0.8)"; // Lighter Yellow-Green (Adjusted from Pale Yellow)
      case "Best":        return "rgba(255, 255, 150, 0.8)"; // Pale Yellow
      case "Inaccuracy":  return "rgba(255, 215, 0, 0.8)";   // Gold/Orange
      case "Mistake":     return "rgba(255, 140, 0, 0.85)";  // Dark Orange
      case "Blunder":     return "rgba(255, 69, 0, 0.9)";    // Red-Orange (Blunder = Reddish)
      default:            return "rgba(128, 128, 128, 0.7)"; // Default Grey
  }
};

// Updated Emojis
export const getEmojiForRating = (rating) => {
    switch (rating) {
        case "Best": return "✅";
        case "Excellent": return "✨";
        case "Good": return "👍";
        case "Inaccuracy": return "🤔";
        case "Mistake": return "😬";
        case "Blunder": return "💀";
        case "Great": return "🔥";
        case "Brilliant": return "👑"; // Galaxy Brain Emoji!
        default: return "";
    }
};

// Slang terms for display
export const getSlangForRating = (rating) => {
    switch (rating) {
        case "Best": return "Clean";
        case "Excellent": return "Nice";
        case "Good": return "Solid";
        case "Inaccuracy": return "Hmm...";
        case "Mistake": return "Yikes";
        case "Blunder": return "OOOF";
        case "Great": return "Spicy!";
        case "Brilliant": return "GALAXY BRAIN!"; // Keep the slang!
        default: return "";
    }
};